import { Stack, UploadSimple, X } from "@phosphor-icons/react";
import { Button, Drawer, Form, Input, InputNumber, Upload, UploadProps } from "antd";
import FormItem from "antd/es/form/FormItem";
import KeyboardButton from "components/Commons/KeyboardButtons";
import toast from "components/Commons/Toaster";
import { createSegment, createVersion, updateSegment } from "pages/Segments/api";
import { useEffect, useState } from "react";
import { useEventListener } from "usehooks-ts";
import { clsx, When } from "x-wings";

type TCreateSegmentDrawer = {
	open: boolean;
	onClose: () => void;
	refetch: () => void;
	selectedSegment: TSegment | undefined;
	segments: TSegment[];
};

type TFormValues = {
	name: string;
	description: string;
	type: "DYNAMIC" | "STATIC";
	recalculation_frequency?: number;
	version: {
		description: string;
		query?: string;
		file?: any;
	};
};

const CreateSegmentDrawer: React.FC<TCreateSegmentDrawer> = ({ open, onClose, refetch, selectedSegment, segments }) => {
	const [form] = Form.useForm<TFormValues>();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const isUpdate = !!selectedSegment;

	useEffect(() => {
		form.resetFields();
	}, [open, form]);

	useEffect(() => {
		if (!selectedSegment) return;
		form.setFieldsValue({
			name: selectedSegment.name,
			description: selectedSegment.description,
			type: selectedSegment.type,
			recalculation_frequency: selectedSegment.recalculation_frequency
		});
	}, [form, selectedSegment]);

	const onFormFinish = async (values: TFormValues) => {
		try {
			setIsSubmitting(true);
			if (isUpdate) {
				const segment = await updateSegment({
					segment_id: selectedSegment._id,
					name: values.name,
					description: values.description,
					recalculation_frequency: values.recalculation_frequency
				});
				toast.success(`Segment ${segment.name} updated successfully!`);
			} else {
				const segment = await createSegment({
					name: values.name,
					description: values.description,
					type: values.type,
					recalculation_frequency: values.recalculation_frequency
				});
				await createVersion(segment.type, {
					segment_id: segment._id,
					description: values.version.description,
					query: values.version.query,
					file: values.version.file?.file
				});
				toast.success(`Segment ${segment.name} created with initial version!`);
			}
			await refetch();
			onClose();
		} finally {
			setIsSubmitting(false);
		}
	};

	useEventListener("keydown", (e) => {
		if (!open) return;
		if ((e.ctrlKey || e.metaKey) && e.key === "Enter") form.submit();
	});

	const uploadProps: UploadProps = {
		accept: ".xlsx",
		multiple: false,
		beforeUpload: (file) => false,
		maxCount: 1
	};

	return (
		<Drawer
			destroyOnClose
			title={
				<header className="w-full h-11 px-4 pt-3 pb-2 bg-gray-50 border-b border-gray-100 justify-between items-center inline-flex">
					<div className="justify-start items-center gap-2.5 flex">
						<div className="p-1 bg-white rounded-md shadow justify-start items-center gap-2 flex">
							<Stack className="w-4 h-4 relative text-violet-600" />
						</div>
						<div className="justify-start items-center gap-2 flex">
							<div className="text-gray-900 text-base font-medium font-['Inter'] leading-normal">
								{selectedSegment?.name ? selectedSegment.name : "Create Segment"}
							</div>
						</div>
					</div>
					<X className="w-4 h-4 relative text-gray-400" role="button" tabIndex={0} onClick={onClose} />
				</header>
			}
			footer={
				<footer className="rounded-xl flex gap-3 justify-end items-center py-3 border-t border-t-solid border-t-gray-100 absolute bottom-0 left-0 right-0 px-3 bg-white">
					<Button onClick={onClose}>Cancel</Button>
					<Button loading={isSubmitting} htmlType="submit" type="primary" onClick={form.submit}>
						Update
						<KeyboardButton keys={["ctrl/⌘", "⏎"]} />
					</Button>
				</footer>
			}
			className="[&_.ant-drawer-header]:p-0 [&_.ant-drawer-body]:p-4 [&_.ant-drawer-body]:pb-14 [&_.ant-drawer-body]:relative"
			closeIcon={false}
			onClose={onClose}
			open={open}>
			<div>
				<Form<TFormValues>
					layout="vertical"
					form={form}
					onFinish={onFormFinish}
					className="grid gap-4"
					initialValues={{
						type: "STATIC",
						recalculation_frequency: 1,
						version: {
							description: "",
							query: "SELECT org_id FROM ",
							file: undefined
						}
					}}>
					{({ type }) => (
						<>
							<FormItem name="type" label="Type">
								<select
									className={clsx("!shadow-none mt-0", isUpdate && "cursor-not-allowed")}
									disabled={isUpdate}>
									<option value="STATIC">Static</option>
									<option value="DYNAMIC">Dynamic</option>
								</select>
							</FormItem>
							<FormItem
								name="name"
								label="Name"
								rules={[
									{
										required: true,
										message: "Segment name is required!"
									},
									{
										validator: async (_, value) => {
											if (value === selectedSegment?.name) return Promise.resolve();
											const isUnique = segments.every((segment) => segment.name !== value);
											return isUnique
												? Promise.resolve()
												: Promise.reject("Segment with same name already exists!");
										}
									}
								]}>
								<Input placeholder="Enter segment name" />
							</FormItem>
							<FormItem
								name="description"
								label="Description"
								rules={[
									{
										required: true,
										message: "Segment description is required!"
									}
								]}>
								<Input.TextArea
									placeholder="Segment description"
									autoSize={{
										minRows: 2,
										maxRows: 3
									}}
								/>
							</FormItem>
							{type === "DYNAMIC" && (
								<FormItem
									name="recalculation_frequency"
									label="Recalculation Frequency (In hours)"
									rules={[
										{
											required: true,
											message: "Recalculation frequency is required!"
										}
									]}>
									<InputNumber min={1} />
								</FormItem>
							)}

							<When isTrue={!isUpdate}>
								<legend className="uppercase !text-xs font-semibold !text-primary-600 tracking-wide !leading-relaxed mt-4 !mb-0">
									Initial Version
								</legend>

								<FormItem
									name={["version", "description"]}
									label="Description"
									rules={[
										{
											required: true,
											message: "Version description is required!"
										}
									]}>
									<Input.TextArea
										placeholder="Version description"
										autoSize={{
											minRows: 2,
											maxRows: 3
										}}
									/>
								</FormItem>
								{type === "DYNAMIC" && (
									<FormItem
										name={["version", "query"]}
										label="SQL Query"
										rules={[
											{
												required: true,
												message: "Query is required!"
											}
										]}>
										<Input.TextArea
											placeholder="Enter the query"
											autoSize={{
												minRows: 2,
												maxRows: 3
											}}
										/>
									</FormItem>
								)}

								{type === "STATIC" && (
									<FormItem
										name={["version", "file"]}
										label={
											<div className="flex items-center gap-1">
												<span>File</span>
												<span className="text-gray-400 text-xs">(xlsx)</span>
												<a
													download
													href="https://stampmyvisa-public.s3.ap-south-1.amazonaws.com/important-docs/Segment+Sample.xlsx"
													className="text-primary-600 hover:underline hover:text-primary-700">
													(Download Template)
												</a>
											</div>
										}
										rules={[
											{
												required: true,
												message: "File is required!"
											}
										]}>
										<Upload.Dragger {...uploadProps}>
											<div
												className="flex justify-center place-items-center cursor-pointer text-gray-600 text-sm"
												role="button"
												tabIndex={0}>
												<UploadSimple size={20} className="mx-1" />
												<span>Upload xlsx file</span>
											</div>
										</Upload.Dragger>
									</FormItem>
								)}
							</When>
						</>
					)}
				</Form>
			</div>
		</Drawer>
	);
};

export default CreateSegmentDrawer;
