import { ArrowRight, Copy, DownloadSimple, Spinner, UploadSimple } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Form, Input, Segmented, Tag, Upload, UploadProps } from "antd";
import { useForm } from "antd/es/form/Form";
import FormItem from "antd/es/form/FormItem";
import { getFileURLfromFilename } from "api/assets";
import EditablePopover from "components/Commons/EditablePopover";
import toast from "components/Commons/Toaster";
import dayjs from "dayjs";
import { createVersion, getVersionsForSegment, makeSegmentVersionLive } from "pages/Segments/api";
import { useEffect, useRef, useState } from "react";
import colors from "tailwindcss/colors";
import { Modal, useCopyToClipboard } from "x-wings";

type TVersionModal = {
	open: boolean;
	onClose: () => void;
	segment: TSegment | null;
	refetchSegments: () => void;
};

type TVersionFormValues = {
	description: string;
	query?: string;
	file?: {
		file: File;
	};
};

const VersionModal = ({ open, onClose, segment, refetchSegments }: TVersionModal) => {
	const [active, setActive] = useState<"new" | "existing">(
		segment?.no_of_versions && segment.no_of_versions > 0 ? "existing" : "new"
	);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isPublishing, setIsPublishing] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const [, copyToClipboard] = useCopyToClipboard();

	const updated = useRef(false);

	useEffect(() => {
		setActive(segment?.no_of_versions && segment.no_of_versions > 0 ? "existing" : "new");
	}, [segment]);

	const [form] = useForm<TVersionFormValues>();

	const uploadProps: UploadProps = {
		accept: ".xlsx",
		multiple: false,
		beforeUpload: (file) => false,
		maxCount: 1
	};

	const {
		data: versions,
		isFetching,
		refetch
	} = useQuery({
		queryKey: ["segment-versions", segment?._id],
		queryFn: () => getVersionsForSegment(segment?._id!),
		enabled: !!segment?._id && active === "existing",
		initialData: []
	});

	const onFormFinish = async (values: TVersionFormValues) => {
		if (!segment) return;
		try {
			setIsSubmitting(true);

			await createVersion(segment.type, {
				segment_id: segment._id,
				description: values.description,
				query: values.query,
				file: values.file?.file
			});
			toast.success(`Version created for ${segment.name} segment!`);
			refetch();
			setActive("existing");
			updated.current = true;
		} finally {
			setIsSubmitting(false);
		}
	};

	const onMakeLive = async (segmentVersion: string, comment?: string) => {
		if (!comment) return;
		try {
			setIsPublishing(true);
			await makeSegmentVersionLive({
				segmentId: segment?._id!,
				segmentVersion,
				comment
			});
			toast.success("Selected version is live now!");
			refetch();
			updated.current = true;
		} finally {
			setIsPublishing(false);
		}
	};
	const onDownloadFile = async (path: string) => {
		if (isDownloading) return;
		try {
			setIsDownloading(true);
			const signedUrls = await getFileURLfromFilename(path);
			const link = document.createElement("a");
			link.href = signedUrls[0].url;
			link.download = signedUrls[0].filename;
			link.click();
		} finally {
			setIsDownloading(false);
		}
	};

	useEffect(() => {
		if (!open) return;
		form.resetFields();
		updated.current = false;
	}, [open, form]);

	useEffect(() => {
		if (updated.current && !open) {
			refetchSegments();
		}
	}, [open, refetchSegments]);

	return (
		<Modal
			title={`${segment?.name} versions`}
			width={540}
			open={open}
			onCancel={onClose}
			footer={
				active === "new" ? (
					<div className="grid grid-cols-1 gap-3">
						<Button type="primary" onClick={form.submit} loading={isSubmitting}>
							Create version
						</Button>
					</div>
				) : null
			}>
			<Segmented<"new" | "existing">
				options={[
					{
						label: "New",
						value: "new"
					},
					{
						label: (
							<span className="flex gap-1 items-center justify-center">
								Existing
								{isFetching && <Spinner className="animate-spin" />}
								{!isFetching && versions.length > 0 && <span>({versions.length})</span>}
							</span>
						),
						value: "existing"
					}
				]}
				value={active}
				onChange={(val) => setActive(val)}
				block
				className="mb-4"
			/>
			<div className="min-h-56">
				{active === "new" && (
					<Form<TVersionFormValues>
						form={form}
						layout="vertical"
						className="grid gap-3"
						initialValues={{
							description: "",
							query: segment?.type === "DYNAMIC" ? "SELECT org_id FROM " : undefined,
							file: undefined
						}}
						onFinish={onFormFinish}>
						<FormItem
							name={"description"}
							label="Description"
							rules={[
								{
									required: true,
									message: "Description is required!"
								},
								{
									validator: async (_, value) => {
										if (!segment) return Promise.resolve();
										const exists = versions.some((_) => _.description === value);
										if (exists) return Promise.reject("Version with description already exists!");
										return Promise.resolve();
									}
								}
							]}>
							<Input.TextArea
								placeholder="Version description"
								autoSize={{
									minRows: 2,
									maxRows: 3
								}}
							/>
						</FormItem>
						{segment?.type === "DYNAMIC" && (
							<FormItem
								name={"query"}
								label="SQL Query"
								rules={[
									{
										required: true,
										message: "Query is required!"
									}
								]}>
								<Input.TextArea
									placeholder="Enter the query"
									autoSize={{
										minRows: 2,
										maxRows: 3
									}}
									defaultValue={"SELECT org_id FROM "}
								/>
							</FormItem>
						)}

						{segment?.type === "STATIC" && (
							<FormItem
								name={"file"}
								label={
									<div className="flex items-center gap-1">
										<span>File</span>
										<span className="text-gray-400 text-xs">(xlsx)</span>
										<a
											download
											href="https://stampmyvisa-public.s3.ap-south-1.amazonaws.com/important-docs/Segment+Sample.xlsx"
											className="text-primary-600 hover:underline hover:text-primary-700">
											(Download Template)
										</a>
									</div>
								}
								rules={[
									{
										required: true,
										message: "File is required!"
									}
								]}>
								<Upload.Dragger {...uploadProps}>
									<div
										className="flex justify-center place-items-center cursor-pointer text-gray-600 text-sm"
										role="button"
										tabIndex={0}>
										<UploadSimple size={20} className="mx-1" />
										<span>Upload xlsx file</span>
									</div>
								</Upload.Dragger>
							</FormItem>
						)}
					</Form>
				)}
				{active === "existing" && (
					<div className="grid grid-cols-1 gap-4 divide-y-[1px] divide-gray-200 max-h-80 overscroll-y-auto scrollbar-hidden">
						{versions.map((_) => (
							<div className="grid gap-1.5 pt-3 relative group">
								<div className="absolute top-2 right-1.5">
									{_.is_live ? (
										<Tag color={colors.emerald[600]} children="Live" />
									) : (
										<EditablePopover
											inputVariant="textarea"
											textAreaProps={{
												placeholder: "Enter the comment",
												autoSize: {
													minRows: 2
												}
											}}
											loading={isPublishing}
											onFinish={(comment) => onMakeLive(_._id, comment)}>
											<Button
												loading={isPublishing}
												type="primary"
												className="!h-5 !text-xs group-hover:!flex !hidden">
												Make Live <ArrowRight />
											</Button>
										</EditablePopover>
									)}
								</div>

								<div className="text-gray-400 text-xs leading-none">
									{dayjs(_.created_at).format("DD MMM, YYYY hh:mm A")}
								</div>
								<div className="flex gap-3 items-start">
									<p className="flex-1 text-sm  text-gray-700">{_.description}</p>
								</div>

								{segment?.type === "DYNAMIC" && (
									<p className="p-1 mx-px  px-2 bg-slate-100 rounded-md ring-gray-200 text-xs font-mono relative group/query ring-1 select-none">
										{_.query}

										<span
											onClick={() => copyToClipboard(_.query)}
											className="invisible group-hover/query:visible  cursor-pointer transition-colors active:bg-emerald-600 active:text-white p-0.5 rounded-md ring-1 ring-slate-300 grid place-items-center absolute top-1 right-1.5 bg-white">
											<Copy size={14} />
										</span>
									</p>
								)}
								{segment?.type === "STATIC" && (
									<div>
										<span
											onClick={() => onDownloadFile(_.query)}
											className="text-primary-600 hover:text-primary-700 hover:underline flex items-center gap-1 text-sm cursor-pointer">
											Download XLSX
											<DownloadSimple />
										</span>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</Modal>
	);
};

export default VersionModal;
