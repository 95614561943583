import React from "react";
import { Route, Routes } from "react-router-dom";
import SegmentsProvider from "./context";
import routes from "./routes";

const Segments: React.FC = () => {
	return (
		<SegmentsProvider>
			<Routes>
				{routes.map((props) => (
					<Route key={props.path?.toString()} element={props.element} {...props} />
				))}
			</Routes>
		</SegmentsProvider>
	);
};

export default Segments;

