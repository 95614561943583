import React, { createContext, useContext } from "react";

type TSegmentsContext = {};

const SegmentsContext = createContext<TSegmentsContext | undefined>(undefined);

const SegmentsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	return <SegmentsContext.Provider value={{}}>{children}</SegmentsContext.Provider>;
};

export default SegmentsProvider;

export const useSegmentsContext = () => {
	const context = useContext(SegmentsContext);

	if (context === undefined) {
		throw new Error("useSegments must be within Segments");
	}
	return context;
};

