import { ENV, MIXPANEL_TOKEN } from "env";
import mixpanel, { Mixpanel as TMixpanel } from "mixpanel-browser";

declare global {
	interface Window {
		mixpanel: TMixpanel;
	}
}

const env_check = ENV !== "development";

if (MIXPANEL_TOKEN === "none" && env_check) {
	// eslint-disable-next-line no-console
	console.warn("🚀 ~ Mixpanel token is not provided");
}
window.mixpanel = mixpanel;
mixpanel.init(MIXPANEL_TOKEN, {
	ignore_dnt: true
});

const actions = {
	identify: (id: string) => {
		if (env_check) mixpanel.identify(id);
	},
	alias: (id: string) => {
		if (env_check) mixpanel.alias(id);
	},
	track: (name: string, props: any = {}) => {
		if (env_check) mixpanel.track(name, props);
	},
	trackOrder: (name: string, order: TVisaOrder, props: any = {}) => {
		props = {
			...props,
			country_symbol: order.country.symbol,
			visa_type: order.visa_type.visa_type,
			travel_start_date: order.travel_start_date,
			travel_end_date: order.travel_end_date,
			number_of_travellers: order.no_of_travelers,
			order_id: order.order_id,
			current_timeline_status: order.timeline_status
		};
		if (env_check) {
			mixpanel.track(name, props);
		}
	},
	time_event: (name: string) => {
		if (env_check) mixpanel.time_event(name);
	},
	reset: () => {
		mixpanel.reset();
	},
	people: {
		set: (props: any) => {
			if (env_check) mixpanel.people.set(props);
		},
		set_once: (props: any) => {
			if (env_check) mixpanel.people.set_once(props);
		}
	}
};

export const MIXPANEL_EVENTS = {
	// Login & sign up events
	RESEND_OTP: "RESEND_OTP",
	LOGIN_SUCCESS: "LOGIN_SUCCESS",
	LOGIN_FAILED: "LOGIN_FAILED",
	LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
	// Order related events
	ORDER_APPLICATION_STATUS_CHANGED: "ORDER_APPLICATION_STATUS_CHANGED",
	ORDER_STATUS_CHANGED: "ORDER_STATUS_CHANGED",
	ORDER_QUESTIONNAIRE_STATUS_CHANGED: "ORDER_QUESTIONNAIRE_STATUS_CHANGED",
	ORDER_DOCUMENT_STATUS_CHANGED: "ORDER_DOCUMENT_STATUS_CHANGED",
	// Change log related events
	CHANGE_LOG_MODAL_OPENED: "CHANGE_LOG_MODAL_OPENED",
	CHANGE_LOG_MODAL_CLOSED: "CHANGE_LOG_MODAL_CLOSED",
	CHANGE_LOG_PAGE_VIEWED: "CHANGE_LOG_PAGE_VIEWED",
	CHANGE_LOG_PAGE_DISMISSED: "CHANGE_LOG_PAGE_DISMISSED",
	CHANGE_LOG_DEMO_CLICKED: "CHANGE_LOG_DEMO_CLICKED",
	// CLASSIFY VERIFY SCREEN
	// ORDER PAGE
	ADD_TRAVELLER: "ADD_TRAVELLER",
	VISA_EXPERT_ASSIGNED: "VISA_EXPERT_ASSIGNED",
	VISA_OPS_ASSIGNED: "VISA_OPS_ASSIGNED",
	DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
	SYNC_INVOICE: "SYNC_INVOICE",
	UPDATE_ORDER_STATUS: "UPDATE_ORDER_STATUS",
	DELETE_APPLICATION_REQUEST: "DELETE_APPLICATION_REQUEST",
	DELETE_APPLICATION: "DELETE_APPLICATION",
	MARK_REMAINING_DOCUMENTS_AS_REJECTED: "MARK_REMAINING_DOCUMENTS_AS_REJECTED",
	UPDATE_TRAVELLER_DETAILS: "UPDATE_TRAVELLER_DETAILS",
	COPY_TRAVELLER_ROW: "COPY_TRAVELLER_ROW",
	VISA_VERDICT_UPDATED: "VISA_VERDICT_UPDATED",
	IMPORT_TRAVELLERS: "IMPORT_TRAVELLERS",
	DOWNLOAD_DOCUMENTS: "DOWNLOAD_DOCUMENTS",
	PAY_FOR_ORDER: "PAY_FOR_ORDER",
	UPDATE_TRAVEL_DATES: "UPDATE_TRAVEL_DATES",
	UPDATE_PAX_COUNT: "UPDATE_PAX_COUNT",
	UPDATE_REFERENCE_NUMBER: "UPDATE_REFERENCE_NUMBER",
	UPDATED_INTERNAL_NOTES: "UPDATED_INTERNAL_NOTES",
	UPDATE_ADDON: "UPDATE_ADDON",
	OVERVIEW_TAB_CHANGED: "OVERVIEW_TAB_CHANGED",
	COPY_TRAVELLERS: "COPY_TRAVELLERS",
	OVERVIEW_PAGE_CHANGED: "OVERVIEW_PAGE_CHANGED",
	DOCUMENTS_TAB_MODE_SWITCHED: "DOCUMENTS_TAB_MODE_SWITCHED",
	ADD_CARD: "ADD_CARD",
	ADD_EMBASSY_APPLICATION_NUMBER: "ADD_EMBASSY_APPLICATION_NUMBER",
	UPDATE_APPOINTMENT_DATE: "UPDATE_APPOINTMENT_DATE",
	UPDATE_TRAVELLER_ADDRESS: "UPDATE_TRAVELLER_ADDRESS",
	UPDATE_COURIER_DETAILS: "UPDATE_COURIER_DETAILS",
	APPLICATION_STATUS_CHANGED: "APPLICATION_STATUS_CHANGED",
	REQUEST_ADDITIONAL_DOCUMENTS: "REQUEST_ADDITIONAL_DOCUMENTS",
	TRIGGER_DUBAI_VENDOR_EMAIL: "TRIGGER_DUBAI_VENDOR_EMAIL",
	ADDED_NEW_CARD: "ADDED_NEW_CARD",
	SELECTED_EXISTING_CARD: "SELECTED_EXISTING_CARD",
	// CLASSIFY VERIFY SCREEN
	DOWNLOAD_ACTIVE_FILE: "DOWNLOAD_ACTIVE_FILE",
	ADD_TO_MULTIPLE: "ADD_TO_MULTIPLE",
	SELECT_COMPRESS_SIZE: "SELECT_COMPRESS_SIZE",
	TOOL_SELECTION: "TOOL_SELECTION",
	COMPRESS_PDF: "COMPRESS_PDF",
	REMOVE_BACKGROUND: "REMOVE_BACKGROUND",
	CROP_DOCUMENT: "CROP_DOCUMENT",
	DELETE_PAGES: "DELETE_PAGES",
	SPLIT_PDF: "SPLIT_PDF",
	MERGE_PDF: "MERGE_PDF",
	CONVERT_IMAGES_TO_PDF: "CONVERT_IMAGES_TO_PDF",
	COMPRESS_IMAGE: "COMPRESS_IMAGE",
	CONVERT_PDF_TO_IMAGES: "CONVERT_PDF_TO_IMAGES",
	CONVERT_IMAGE: "CONVERT_IMAGE",
	TOOLS_KEYBOARD_SHORTCUTS: "TOOLS_KEYBOARD_SHORTCUTS",
	ZOOM_SCALE_DOCUMENT: "ZOOM_SCALE_DOCUMENT",
	RENAME_DOCUMENT: "RENAME_DOCUMENT",
	APPROVE_DOCUMENT: "APPROVE_DOCUMENT",
	DELETE_DOCUMENT: "DELETE_DOCUMENT",
	REJECT_DOCUMENT: "REJECT_DOCUMENT",
	CLASSIFY_SCREEN_KEYBOARD_SHORTCUT: "CLASSIFY_SCREEN_KEYBOARD_SHORTCUT",
	SEARCH_DOCUMENT_TYPE: "SEARCH_DOCUMENT_TYPE",
	SELECT_DOCUMENT_TYPE: "SELECT_DOCUMENT_TYPE",
	DROP_DOCUMENT_ON_CATEGORY: "DROP_DOCUMENT_ON_CATEGORY",
	DOCUMENT_MAP_TO_TRAVELLER: "DOCUMENT_MAP_TO_TRAVELLER",
	ADD_DOCUMENT_TO_TRAVELLER: "ADD_DOCUMENT_TO_TRAVELLER",
	DOCUMENT_UNMAP_FROM_TRAVELLER: "DOCUMENT_UNMAP_FROM_TRAVELLER",
	UPDATE_CATEGORY_STATUS: "UPDATE_CATEGORY_STATUS",
	UPDATE_DOCUMENT_STATUS: "UPDATE_DOCUMENT_STATUS",
	DOWNLOAD_TRAVELLER_DOCUMENTS: "DOWNLOAD_TRAVELLER_DOCUMENTS",
	DELETE_TRAVELLER: "DELETE_TRAVELLER",
	APPROVE_ALL_DOCUMENTS: "APPROVE_ALL_DOCUMENTS",
	// Logistics events
	LOGISTIC_ORDER: {
		FILTER_CHANGE: "LOGISTIC_ORDER/FILTER_CHANGE",
		NAVIGATE_TO_TASKS: "LOGISTIC_ORDER/NAVIGATE_TO_TASKS"
	},
	LOGISTIC_TASK: {
		FILTER_CHANGE: "LOGISTIC_TASK/FILTER_CHANGE",
		NAVIGATE_TO_ORDER: "LOGISTIC_TASK/NAVIGATE_TO_ORDER",
		OPEN_MARK_COMPLETE_MODAL: "LOGISTIC_TASK/OPEN_MARK_COMPLETE_MODAL",
		OPEN_TASK_DETAILS: "LOGISTIC_TASK/OPEN_TASK_DETAILS",
		OPEN_PAYMENT_RECEIPT_UPLOAD_MODAL: "LOGISTIC_TASK/OPEN_PAYMENT_RECEIPT_UPLOAD_MODAL"
	},
	// ORDERS PAGE
	BULK_VISA_UPLOAD: "BULK_VISA_UPLOAD",
	DOWNLOAD_BILLS: "DOWNLOAD_BILLS",
	ORDER_INTERNAL_TAGS_UPDATED: "ORDER_INTERNAL_TAGS_UPDATED",
	ORDER_INTERNAL_TAGS_REMOVED: "ORDER_INTERNAL_TAGS_REMOVED",
	DOCUMENT_STATUS_CHANGED: "DOCUMENT_STATUS_CHANGED",
	FILTER_ORDERS: "FILTER_ORDERS",
	ORDERS_NEXT_PAGE: "ORDERS_NEXT_PAGE",
	ORDERS_PREV_PAGE: "ORDERS_PREV_PAGE",
	REQUEST_CARD: "REQUEST_CARD",
	REMOVE_REQUEST_CARD: "REMOVE_REQUEST_CARD",
	SEARCH_ORDERS: "SEARCH_ORDERS",
	ASSIGNEE_FILTER_CHANGE: "ASSIGNEE_FILTER_CHANGE",
	OPEN_TEAM_OVERVIEW: "OPEN_TEAM_OVERVIEW",
	SWITCH_ORDER_ID_TYPE: "SWITCH_ORDER_ID_TYPE",
	SWITCH_INVOICE_NO_TYPE: "SWITCH_INVOICE_NO_TYPE",
	ORDERS_SORT_BY_TRAVEL_DATE: "ORDERS_SORT_BY_TRAVEL_DATE",
	ORDERS_SORT_BY_CREATED_AT: "ORDERS_SORT_BY_CREATED_AT",
	VISA_ORDER_INTERNAL_TAG_FILTER_CHANGE: "VISA_ORDER_INTERNAL_TAG_FILTER_CHANGE",
	COPY_INVOICE_NO: "COPY_INVOICE_NO",
	CREATE_NEW_INVOICE_OPEN: "CREATE_NEW_INVOICE_OPEN",
	CREATE_REFUND: "CREATE_REFUND",
	OPEN_INVOICE_PREVIEW: "OPEN_INVOICE_PREVIEW",
	SEND_INVOICE: "SEND_INVOICE",
	INVOICE_LINE_ITEM_CHANGED: "INVOICE_LINE_ITEM_CHANGED",
	INVOICE_LINE_ITEM_DELETED: "INVOICE_LINE_ITEM_DELETED",
	INVOICE_LINE_ITEM_ADDED: "INVOICE_LINE_ITEM_ADDED",

	APPLICATIONS_TAB: {
		FILTER_D_DAY: "APPLICATIONS_TAB_FILTER_D_DAY",
		SORT: "APPLICATIONS_TAB_SORT",
		ASSIGNEE_FILTER_CHANGE: "APPLICATIONS_TAB_ASSIGNEE_FILTER_CHANGE",
		STATUS_FILTER_CHANGE: "APPLICATIONS_TAB_STATUS_FILTER_CHANGE",
		ACTIONS_FILTER_CHANGE: "APPLICATIONS_TAB_ACTIONS_FILTER_CHANGE",
		SEARCH_TEXT: "APPLICATIONS_TAB_SEARCH_TEXT"
	},

	MARK_INVOICE_AS_SEND: "MARK_INVOICE_AS_SEND",
	CREATE_NEW_INVOICE: "CREATE_NEW_INVOICE",
	UPDATE_INVOICE: "UPDATE_INVOICE",
	UPDATE_INVOICE_DUE_DATE: "UPDATE_INVOICE_DUE_DATE",
	SET_INVOICE_BRANCH: "SET_INVOICE_BRANCH",
	CREATE_LINE_ITEM: "CREATE_LINE_ITEM",
	EDIT_LINE_ITEM: "EDIT_LINE_ITEM",
	// KYC PAGE
	KYC_SORT_BY_LAST_UPDATED: "KYC_SORT_BY_LAST_UPDATED",
	KYC_VIEW_DOCUMENT: "KYC_VIEW_DOCUMENT",
	KAM_ASSIGNED: "KAM_ASSIGNED",
	KYC_FILTER_STATUS: "KYC_FILTER_STATUS",
	KYC_SEARCH: "KYC_SEARCH",
	// WALLET
	WALLET_MONEY_ADDED: "WALLET_MONEY_ADDED",
	WALLET_HISTORY_VIEWED: "WALLET_HISTORY_VIEWED",
	// Click To Call
	CALL_INITIATED: "CALL_INITIATED",
	// REPORTS
	DASHBOARD_SELECTED: "DASHBOARD_SELECTED",
	VISA_FORM: {
		CLONE_ANSWER: "CLONE_ANSWER"
	}
};

export const Mixpanel = { ...actions, events: MIXPANEL_EVENTS };
