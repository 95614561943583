import { SVGProps } from "react";
interface Props extends SVGProps<SVGSVGElement> {
	selected?: "up" | "down";
}

const SortIcon = ({ selected, ...props }: Props) => (
	<svg height="14" width="14" fill="none" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
		<rect height="14" width="14" fill="white" />
		<path
			d="M9.73113 5.09558C9.71222 5.14126 9.68019 5.18031 9.63908 5.20779C9.59797 5.23527 9.54964 5.24994 9.5002 5.24995H4.5002C4.45072 5.24999 4.40235 5.23535 4.3612 5.20788C4.32005 5.18041 4.28798 5.14136 4.26904 5.09565C4.2501 5.04994 4.24515 4.99965 4.25481 4.95113C4.26448 4.9026 4.28832 4.85804 4.32332 4.82308L6.82332 2.32308C6.84654 2.29983 6.87411 2.28139 6.90446 2.26881C6.93481 2.25623 6.96734 2.24976 7.0002 2.24976C7.03305 2.24976 7.06558 2.25623 7.09593 2.26881C7.12628 2.28139 7.15385 2.29983 7.17707 2.32308L9.67707 4.82308C9.71202 4.85806 9.73582 4.90262 9.74544 4.95113C9.75506 4.99963 9.75008 5.0499 9.73113 5.09558Z"
			fill={selected === "up" ? "#4F46E5" : "#9AA4B2"}
		/>
		<path
			d="M9.67707 9.17688L7.17707 11.6769C7.15385 11.7001 7.12628 11.7186 7.09593 11.7311C7.06558 11.7437 7.03305 11.7502 7.0002 11.7502C6.96734 11.7502 6.93481 11.7437 6.90446 11.7311C6.87411 11.7186 6.84654 11.7001 6.82332 11.6769L4.32332 9.17688C4.28832 9.14191 4.26448 9.09735 4.25481 9.04883C4.24515 9.00031 4.2501 8.95001 4.26904 8.9043C4.28798 8.8586 4.32005 8.81954 4.3612 8.79207C4.40235 8.7646 4.45072 8.74996 4.5002 8.75H9.5002C9.54967 8.74996 9.59804 8.7646 9.63919 8.79207C9.68034 8.81954 9.71241 8.8586 9.73135 8.9043C9.75029 8.95001 9.75524 9.00031 9.74558 9.04883C9.73592 9.09735 9.71207 9.14191 9.67707 9.17688Z"
			fill={selected === "down" ? "#4F46E5" : "#9AA4B2"}
		/>
	</svg>
);

export default SortIcon;

