import { Switch } from "antd";
import EditablePopover from "components/Commons/EditablePopover";
import toast from "components/Commons/Toaster";
import { disableSegment, enableSegment } from "pages/Segments/api";
import { useState } from "react";

type TToggleSegmentActive = {
	initialValue: boolean;
	segmentId: string;
	refetch: () => void;
};

const ToggleSegmentActive = ({ initialValue, segmentId, refetch }: TToggleSegmentActive) => {
	const [loading, setLoading] = useState(false);

	const onToggleSegmentActive = async (comment?: string) => {
		if (!comment) return;
		try {
			setLoading(true);
			if (initialValue) await disableSegment({ segmentId, comment });
			else
				await enableSegment({
					segmentId,
					comment
				});
			await refetch();
			toast.success(`Segment will ${initialValue ? "be disabled" : "be enabled"} shortly!`);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div onClick={(e) => e.stopPropagation()}>
			<EditablePopover
				inputVariant="textarea"
				textAreaProps={{
					placeholder: "Enter the comment",
					autoSize: {
						minRows: 2
					}
				}}
				loading={loading}
				onFinish={onToggleSegmentActive}>
				<Switch value={initialValue} />
			</EditablePopover>
		</div>
	);
};

export default ToggleSegmentActive;

