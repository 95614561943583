import { Layout } from "antd";
import ErrorBoundary from "components/Commons/ErrorBoundary";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { useAuthContext } from "pages/Auth/Context";
import React, { Suspense, useEffect, useLayoutEffect } from "react";
import { Navigate, Outlet, useLocation, useSearchParams } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import Sidebar from "./Sidebar";

import { Spinner } from "@phosphor-icons/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import clsx from "clsx";
import { isMobile } from "react-device-detect";
import { SIDEBAR_HIDDEN_PAGES } from "./constants";

const { Content } = Layout;
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false
		}
	}
});

const MainLayout: React.FC = () => {
	const { value: sidebar, setValue: setSidebar } = useBoolean(true);
	const location = useLocation();
	const [searchParams] = useSearchParams();
	const { user, isLoading } = useAuthContext();

	function LazyLoad() {
		useEffect(() => {
			NProgress.start();

			return () => {
				NProgress.done();
			};
		});

		return (
			<div className="grid place-items-center h-[calc(100vh_-_16px)]">
				<Spinner size={28} className="spin text-primary-600" />
			</div>
		);
	}

	useLayoutEffect(() => {
		const pathChunks = location.pathname.split("/");

		const handleSidebarVisibility = (chunks: string[]) => {
			if (chunks.length === 0) return;

			for (let page of SIDEBAR_HIDDEN_PAGES) {
				const pageChunks = page.split("/");

				const isMatch = pageChunks.every((chk, index) => {
					if (chk.startsWith(":")) return true;
					return chk === pathChunks[index];
				});

				if (isMatch) {
					setSidebar(false);
					return;
				}
			}

			setSidebar(true);
		};

		handleSidebarVisibility(pathChunks);
	}, [location, setSidebar]);

	if (isLoading) {
		return (
			<div className="fixed overflow-hidden inset-0 h-screen w-screen bg-white grid place-items-center">
				<Spinner size={40} className="spin text-primary-600" />
			</div>
		);
	}

	if (!user)
		return (
			<Navigate
				to={{
					pathname: "/auth/sign-in",
					search: (() => {
						const query = new URLSearchParams(searchParams);
						query.set("redirectTo", location.pathname);
						return query.toString();
					})()
				}}
			/>
		);

	return (
		<Layout hasSider={sidebar}>
			{sidebar && <Sidebar />}
			<ErrorBoundary user={user}>
				<Layout className="bg-slate-100">
					<QueryClientProvider client={queryClient}>
						<Content
							className={clsx(
								"bg-white shadow-1",
								sidebar && !isMobile
									? "rounded-tl-2xl overflow-y-auto mt-2 ms-px h-[calc(100svh_-_16px)] scrollbar-hidden"
									: "h-svh overflow-y-auto scrollbar-hidden",
								isMobile && "pt-12"
							)}>
							<Suspense fallback={<LazyLoad />}>
								<Outlet />
							</Suspense>
						</Content>
					</QueryClientProvider>
				</Layout>
			</ErrorBoundary>
		</Layout>
	);
};

export default MainLayout;
