import { ArrowRight, Plus, Spinner, Stack } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { Button, Checkbox, ConfigProvider, Divider, Switch, Table, Tag, Tooltip } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { SorterResult, SortOrder } from "antd/es/table/interface";
import EditablePopover from "components/Commons/EditablePopover";
import PageHeader from "components/Commons/PageHeader";
import Pagination from "components/Commons/Pagination/Pagination";
import toast from "components/Commons/Toaster";
import dayjs from "dayjs";
import SortIcon from "icons/SortIcon";
import { deleteSegment, getSegments, runSegment } from "pages/Segments/api";
import React, { useState } from "react";
import colors from "tailwindcss/colors";
import { clsx, useLocalStorage } from "x-wings";
import CreateSegmentDrawer from "../CreateDrawer";
import ToggleSegmentActive from "../ToggleSegmentActive";
import VersionModal from "../VersionsModal";
import "./styles.scss";

const perPageRecords = 10;
const SegmentsTable = () => {
	const [selectedSegment, setSelectedSegment] = useState<"new" | TSegment | null>(null);
	const [segmentForVersion, setSegmentForVersion] = useState<TSegment | null>(null);
	const [sortDirection, setSortDirection] = useState<SortOrder | undefined>("descend");
	const [showDeletedSegments, setShowDeletedSegments] = useLocalStorage("show-deleted-segments", false);

	const [currentPage, setCurrentPage] = useState(1);

	const {
		data: segments,
		isFetching,
		refetch
	} = useQuery({
		queryKey: ["segments"],
		queryFn: getSegments,
		initialData: []
	});

	const onDeleteSegment = async ({ segmentId, comment }: { segmentId: string; comment?: string }) => {
		if (!comment) return;
		try {
			await deleteSegment({
				segmentId,
				comment
			});
			refetch();
			toast.success("Segment deleted successfully!");
		} finally {
		}
	};

	const onRunSegment = (segmentId: string) => async (e: React.MouseEvent) => {
		e.stopPropagation();
		try {
			await runSegment(segmentId);
			toast.success("Segment successfully scheduled for execution!");
		} finally {
		}
	};

	const handleChange: TableProps<TSegment>["onChange"] = (pagination, filters, sorter) => {
		if ((sorter as SorterResult).columnKey === "updated_at") {
			setSortDirection((sorter as SorterResult).order);
		}
	};

	const columns: ColumnsType<TSegment> = [
		{
			title: "Segment Name",
			dataIndex: "name",
			key: "name",
			fixed: "left",
			render: (text: string) => <span className="font-semibold">{text}</span>
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			render: (text: string) => (
				<span>
					{text.length > 50 ? (
						<Tooltip title={text} placement="top" className="!w-[200px]">
							{text.slice(0, 50)}...
						</Tooltip>
					) : (
						text
					)}
				</span>
			)
		},
		{
			title: "Type",
			dataIndex: "type",
			render: (val) =>
				val === "STATIC" ? (
					<Tag color={colors.amber[600]} children="Static" />
				) : (
					<Tag color={colors.blue[500]} children="Dynamic" />
				)
		},
		{
			title: "No. of Orgs",
			dataIndex: "no_of_orgs",
			key: "no_of_orgs"
		},
		{
			title: "Versions",
			dataIndex: "no_of_versions",
			render: (value, record) => (
				<div className="flex items-center gap-2">
					<Button
						size="small"
						type="primary"
						className={"!h-5 flex items-center gap-1"}
						onClick={(e) => {
							e.stopPropagation();
							setSegmentForVersion(record);
						}}>
						{!value ? "Create version" : `${value} versions`} <ArrowRight />
					</Button>
				</div>
			)
		},
		{
			title: "Active",
			dataIndex: "is_active",
			key: "is_active",
			render: (isActive: boolean, record) => (
				<div className="flex gap-2 items-center">
					<ToggleSegmentActive initialValue={isActive} segmentId={record._id} refetch={refetch} />
					<Button
						type="primary"
						size="small"
						className="!h-5"
						onClick={onRunSegment(record._id)}
						disabled={!isActive}>
						Run <ArrowRight />
					</Button>
				</div>
			)
		},
		{
			title: "Deleted",
			dataIndex: "is_deleted",
			key: "is_deleted",
			render: (isDeleted: boolean, record) => (
				<div onClick={(e) => e.stopPropagation()}>
					<EditablePopover
						disabled={isDeleted}
						inputVariant="textarea"
						onFinish={(comment) =>
							onDeleteSegment({
								segmentId: record._id,
								comment
							})
						}
						textAreaProps={{
							placeholder: "Enter the comment",
							autoSize: {
								minRows: 2
							}
						}}>
						<Switch
							value={isDeleted}
							disabled={isDeleted}
							className="[&.ant-switch-checked_.ant-switch-inner]:bg-red-600"
						/>
					</EditablePopover>
				</div>
			)
		},
		{
			title: "Updated At",
			dataIndex: "updated_at",
			key: "updated_at",
			sorter: true,
			sortDirections: ["descend", "ascend"],
			showSorterTooltip: false,
			defaultSortOrder: sortDirection,
			sortIcon: ({ sortOrder }) => (
				<SortIcon selected={sortOrder === "ascend" ? "up" : sortOrder === "descend" ? "down" : undefined} />
			),
			render: (text: string) => <span>{dayjs(text).format("DD MMM, YY hh:mm A")}</span>
		}
	];

	const sortFunction = (a: TSegment, b: TSegment) => {
		if (sortDirection === "ascend") {
			return dayjs(a.updated_at).valueOf() - dayjs(b.updated_at).valueOf();
		} else {
			return dayjs(b.updated_at).valueOf() - dayjs(a.updated_at).valueOf();
		}
	};

	const activeSegments = segments
		.sort(sortFunction)
		.filter((segment) => (showDeletedSegments ? true : !segment.is_deleted))
		.slice((currentPage - 1) * perPageRecords, (currentPage - 1) * perPageRecords + perPageRecords);

	return (
		<>
			<PageHeader>Segments</PageHeader>
			<main className="~px-4/8 mt-5">
				<div className="w-full justify-between items-center inline-flex mb-6">
					<div className="justify-start items-center gap-2 flex">
						<Stack className="w-5 h-5 relative text-gray-500" />
						<div className="text-black text-base font-semibold font-['Inter'] leading-normal flex items-center gap-3">
							Segments
						</div>
					</div>

					<div className="ms-auto">
						<Checkbox
							checked={showDeletedSegments}
							onChange={(e) => setShowDeletedSegments(e.target.checked)}>
							Show Deleted
						</Checkbox>
						<Divider type="vertical" className="h-7" />
						<Button
							type="primary"
							icon={<Plus />}
							className="!h-7"
							onClick={() => setSelectedSegment("new")}>
							Create Segment
						</Button>
					</div>
				</div>

				<ConfigProvider
					renderEmpty={() => (
						<div
							style={{ minHeight: perPageRecords * 40 - 32 }}
							className={clsx(
								"flex flex-col justify-center items-center",
								isFetching && "animate-pulse"
							)}>
							{!isFetching && (
								<>
									<span className="text-gray-500 font-medium mb-1 text-[13px] leading-4">
										No Segments found!
									</span>
									<p className="text-center text-gray-400 text-xs leading-tight">
										(Please check the filters applied)
									</p>
								</>
							)}
						</div>
					)}>
					<Table
						onChange={handleChange}
						columns={columns}
						tableLayout="fixed"
						dataSource={activeSegments}
						onRow={(record) => ({
							className: clsx(record.is_deleted && "pointer-events-none opacity-50 line-through"),
							onClick: () => setSelectedSegment(record)
						})}
						loading={{
							spinning: isFetching,
							indicator: (
								<div
									style={{ minHeight: perPageRecords * 40 }}
									className={clsx("flex flex-col justify-center items-center ")}>
									<Spinner className="animate-spin text-primary-600" size={28} />
								</div>
							)
						}}
						scroll={{
							x: true
						}}
						className={"segments"}
						pagination={false}
					/>
					<Pagination
						total={segments.length}
						currentPage={currentPage}
						handleNextPage={() => setCurrentPage((prev) => prev + 1)}
						handlePrevPage={() => setCurrentPage((prev) => prev - 1)}
						perPage={perPageRecords}
						label="Segments"
					/>
				</ConfigProvider>

				<CreateSegmentDrawer
					open={!!selectedSegment}
					onClose={() => setSelectedSegment(null)}
					refetch={refetch}
					selectedSegment={selectedSegment && selectedSegment !== "new" ? selectedSegment : undefined}
					segments={segments}
				/>
				<VersionModal
					open={!!segmentForVersion}
					onClose={() => setSegmentForVersion(null)}
					segment={segmentForVersion}
					refetchSegments={refetch}
				/>
			</main>
		</>
	);
};

export default SegmentsTable;
