import { RouteProps } from "react-router-dom";
import SegmentsTable from "./components/SegmentsTable";

const routes: RouteProps[] = [
	{
		path: "*",
		element: <SegmentsTable />
	}
];

export default routes;

