import { ArrowElbowDownLeft, CircleNotch } from "@phosphor-icons/react";
import { Form, Input, InputProps, InputRef, Popover, PopoverProps } from "antd";
import { TextAreaProps } from "antd/es/input";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useBoolean, useEventListener } from "usehooks-ts";
import { Button } from "x-wings";
import KeyboardButton from "../KeyboardButtons";
import toast from "../Toaster";
import "./styles.scss";

type TFormValues = {
	"editable-input": string;
};

type EditablePopoverPropsType = PopoverProps & {
	inputVariant?: "input" | "textarea";
	label?: React.ReactElement;
	defaultValue?: string;
	value?: string;
	onChange?: (value?: string) => any;
	onFinish?: (value?: string) => any;
	inputProps?: InputProps;
	textAreaProps?: TextAreaProps;
	children: React.ReactNode;
	defaultSelected?: boolean;
	disabled?: boolean;
	validationPattern?: RegExp;
	errorMessage?: string;
	loading?: boolean;
};

const EditablePopover: React.FC<EditablePopoverPropsType> = ({
	inputVariant = "input",
	label,
	children,
	defaultValue,
	value,
	inputProps,
	textAreaProps,
	onOpenChange,
	onChange,
	onFinish,
	disabled = false,
	defaultSelected = true,
	validationPattern,
	loading,
	errorMessage = "Please enter a valid value!",
	open: openProp,
	...rest
}) => {
	const inputRef = useRef<InputRef & TextAreaRef>(null);
	const [isWarnedForCancel, setIsWarnedForCancel] = useState(false);
	const { value: open, setTrue: onOpen, setFalse: onClose, setValue: setOpen } = useBoolean();

	const [form] = Form.useForm<TFormValues>();

	useEffect(() => {
		if (open) {
			form.setFieldValue("editable-input", value || defaultValue);
		}
	}, [defaultValue, form, open, value]);

	useEffect(() => {
		if (open) {
			inputRef.current?.focus({ preventScroll: true });
			if (defaultSelected) {
				inputRef.current?.input?.select();
				inputRef.current?.resizableTextArea?.textArea?.select();
			}
		}
	}, [defaultSelected, open]);

	useEffect(() => {
		if (!openProp) return;
		setOpen(openProp);
	}, [openProp, setOpen]);

	const handleClickSubmit: React.FormEventHandler<HTMLFormElement> | undefined = (event) => {
		event.preventDefault();
		if (loading) return;
		const inputValue = inputRef?.current?.input?.value;
		if (validationPattern && inputValue && !validationPattern.test(inputValue)) {
			toast.warning(errorMessage);
			return;
		}
	};

	const handleOpenChange = (open: boolean) => {
		onOpenChange?.(open);
		if (disabled) return;
		if (open && openProp === undefined) {
			onOpen();
		} else if (value || inputRef?.current?.input?.value) {
			if (isWarnedForCancel) {
				setIsWarnedForCancel(false);
				onClose();
			} else {
				setIsWarnedForCancel(true);
			}
		} else {
			onClose();
		}
	};

	useEventListener("keydown", (event) => {
		if (open && event.key === "Escape") {
			onClose();
		}
	});

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.key === "Enter" && !event.shiftKey) {
			event.preventDefault();
			form.submit();
		}
	};

	const onFormFinish = (values: TFormValues) => {
		if (loading) return;
		const inputValue = values["editable-input"].trim();
		if (validationPattern && inputValue && !validationPattern.test(inputValue)) {
			toast.warning(errorMessage);
			return;
		}
		onFinish?.(inputValue);
		onClose();
	};

	if (true)
		return (
			<Popover
				content={
					<div
						className={clsx(
							"editable-popover-content-container",
							isWarnedForCancel && "editable-popover-content-container-warned"
						)}>
						<Form layout="vertical" form={form} onFinish={onFormFinish} className="grid gap-2">
							<Form.Item
								label={label}
								name="editable-input"
								className="bg-white rounded-lg ring-1 ring-gray-200 p-2"
								initialValue={defaultValue}
								status={isWarnedForCancel ? "error" : ""}>
								{inputVariant === "textarea" ? (
									<TextArea
										size="small"
										autoSize={{ minRows: 2, maxRows: 6 }}
										{...textAreaProps}
										ref={inputRef}
										onKeyDown={onKeyDown}
									/>
								) : (
									<Input size="small" {...inputProps} ref={inputRef} onKeyDown={onKeyDown} />
								)}
							</Form.Item>

							<div className="grid grid-cols-2 gap-2 mb-1 px-1">
								<Button
									type="default"
									size="small"
									className="!h-7"
									onClick={onClose}
									disabled={loading}>
									Cancel <KeyboardButton keys={["ESC"]} />
								</Button>
								<Button
									type="primary"
									size="small"
									className="!h-7"
									htmlType="submit"
									loading={loading}>
									Submit
									<ArrowElbowDownLeft weight="bold" className="inline" />
								</Button>
							</div>
						</Form>
					</div>
				}
				destroyTooltipOnHide={true}
				open={open}
				arrow={false}
				trigger="click"
				onOpenChange={handleOpenChange}
				{...rest}>
				{children}
			</Popover>
		);

	return (
		<Popover
			content={
				<div
					className={clsx(
						"editable-popover-content-container",
						isWarnedForCancel && "editable-popover-content-container-warned"
					)}>
					{label && <div className={clsx("editable-popover-content-title")}>{label}</div>}
					<form onSubmit={handleClickSubmit}>
						{inputVariant === "textarea" ? (
							<TextArea
								autoSize={{ minRows: 2, maxRows: 6 }}
								{...textAreaProps}
								ref={inputRef}
								status={isWarnedForCancel ? "error" : ""}
								defaultValue={defaultValue}
								value={value}
								onChange={(e) => onChange?.(e.target.value)}
							/>
						) : (
							<Input
								suffix={
									loading ? (
										<CircleNotch className="animate-spin" />
									) : (
										<Button
											size="small"
											className="!size-6"
											htmlType="submit"
											icon={<ArrowElbowDownLeft weight="bold" />}></Button>
									)
								}
								size="large"
								{...inputProps}
								ref={inputRef}
								status={isWarnedForCancel ? "error" : ""}
								defaultValue={defaultValue}
								value={value}
								onChange={(e) => onChange?.(e.target.value)}
							/>
						)}
					</form>
				</div>
			}
			destroyTooltipOnHide={true}
			open={open}
			trigger="click"
			arrow={false}
			onOpenChange={handleOpenChange}
			align={{ offset: [0, 50] }}
			{...rest}>
			{children}
		</Popover>
	);
};

export default EditablePopover;
