import { apiInstance } from "api/instance";

export const getSegments = async () =>
	(await apiInstance.get<ApiResponse<TSegment[]>>("segmentation/segments")).data.data;

export const createSegment = async (payload: {
	name: string;
	description: string;
	type: "DYNAMIC" | "STATIC";
	recalculation_frequency?: number;
}) => (await apiInstance.post<ApiResponse<TSegment>>("segmentation/segment", payload)).data.data;

export const updateSegment = async (payload: {
	segment_id: string;
	name: string;
	description: string;
	recalculation_frequency?: number;
}) => (await apiInstance.put<ApiResponse<TSegment>>("segmentation/segment", payload)).data.data;

export const createVersion = async (
	type: "DYNAMIC" | "STATIC",
	data: {
		description: string;
		segment_id: string;
		file?: File;
		query?: string;
	}
) => {
	let payload;
	if (type === "DYNAMIC") {
		payload = {
			description: data.description,
			segment_id: data.segment_id,
			query: data.query
		};
	} else {
		payload = new FormData();
		payload.append("description", data.description);
		payload.append("segment_id", data.segment_id);
		payload.append("file", data.file!);
	}

	return (
		await apiInstance.post<ApiResponse<any>>(
			`segmentation/${type === "DYNAMIC" ? "dynamic" : "static"}-segment-version`,
			payload
		)
	).data.data;
};

export const enableSegment = async (payload: { segmentId: string; comment: string }) =>
	(await apiInstance.put<ApiResponse<TSegment>>("segmentation/enable-segment", payload)).data.data;

export const disableSegment = async (payload: { segmentId: string; comment: string }) =>
	(await apiInstance.put<ApiResponse<TSegment>>("segmentation/disable-segment", payload)).data.data;

export const deleteSegment = async (payload: { segmentId: string; comment: string }) =>
	(await apiInstance.put<ApiResponse<TSegment>>("segmentation/delete-segment", payload)).data.data;

export const getVersionsForSegment = async (segmentId: string) =>
	(await apiInstance.get<ApiResponse<TSegmentVersion[]>>(`segmentation/segment-versions/${segmentId}`)).data.data;

export const makeSegmentVersionLive = async (payload: { segmentId: string; segmentVersion: string; comment: string }) =>
	await apiInstance.put<ApiResponse>(`segmentation/make-segment-version-live`, payload);

export const runSegment = async (segmentId: string) =>
	await apiInstance.get<ApiResponse>(`segmentation/run-segment/${segmentId}`);

